import React from "react";
import { Link } from "react-router-dom";

const InsightDetail = () => {
  return (
    <div>
      <Link
        to="/"
        className="w-10 h-10 rounded-full flex justify-center items-center bg-gray-200 text-white fixed top-5 left-5"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className="h-6 w-6"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={2}
            d="M15 19l-7-7 7-7"
          />
        </svg>
      </Link>
      <div className="flex justify-center items-center">
        <div className=" py-20 mainContainer">
          <div className="col-span-12 text-2xl font-black mb-4 mx-10">
            INSIGHTS
          </div>
          <div className="flex justify-center">
            <img
              src="https://images.pexels.com/photos/374870/pexels-photo-374870.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=650&w=940"
              alt="ima"
              style={{
                width: "90%",
                height: 500,
                objectFit: "cover",
              }}
            />
          </div>
          <div className="col-span-12 text-2xl text-gray-400 font-black  m-4">
            The title
          </div>
          <div className="col-span-12 text-base text-gray-400 text-left  m-4">
            According to Tax Administration (Transfer Pricing) Regulations,
            2018, transfer pricing can be interpreted as the inter-party
            transaction of goods, services and intangible properties with clear
            distinction of the applicability of tax administrative laws between
            business associated transactions and inter-person transactions.
          </div>
          <div className="col-span-12 text-base text-gray-400 text-left  m-4">
            This distinction is pertinent in understanding the difference
            between the associated parties involved the transaction as
            independent market forces can shape the commercial pricing of goods,
            services and intangibles transacted between individuals , as to
            where business transactions between associates may not always
            reflect the dynamics of market forces.
          </div>
          <div className="col-span-12 text-base text-gray-400 text-left  m-4">
            These guidelines do not intend to be prescriptive or exhaustive of
            every transfer pricing issue that may arise. The Guideline does not
            take precedence over tax legislations. Therefore, the Guidelines are
            meant to guide on the application of the Tax Administration
            (Transfer Pricing) Regulations of 2018.
          </div>
        </div>
      </div>
    </div>
  );
};

export default InsightDetail;
