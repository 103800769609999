import React, { useEffect } from "react";
// aos improts
import AOS from "aos";
import "./../../node_modules/aos/dist/aos.css";

import { Link } from "react-router-dom";
import Slide from "./slide";

const Landing = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  AOS.init({
    duration: 1000,
  });
  return (
    <div
      style={{ background: "#bab9b9" }}
      className="grid grid-cols-12   overflow-hidden"
    >
      {/* <div
        data-aos="zoom-out"
        className="uppercase text-lg flex items-end  underline md:col-start-2 md:col-span-10 col-span-12"
        style={{
          backgroundImage: `url(${"https://1.bp.blogspot.com/-IyrhqcJIaiI/YP7xqYCtlJI/AAAAAAAABV4/Do4eubkcXLojiu3YI89akYOZdEBu2RbLACLcBGAsYHQ/s16000/homepage.jpg"})`,
          backgroundPosition: "center",
          backgroundSize: "cover",
          height: "80vh",
        }}
      >
        <div
          className=" w-full"
          style={{
            height: "20vh",
            backgroundImage:
              "linear-gradient(to bottom, rgba(0,0,0,0), #bab9b9)",
          }}
        />
      </div> */}
      <div className="w-full col-span-12 relative">
        <Slide />
      </div>
      <div className=" text-lg flex mt-10 flex-wrap  md:col-start-2 md:col-span-10 col-span-12">
        <div className="flex flex-col md:w-1/3 w-full px-4 md:px-0">
          <div
            className=" bg-black w-full"
            style={{
              height: 1,
            }}
          />
          <div className="text-lg py-3" data-aos="zoom-out">
            DEDICATED LAWYERS
          </div>
          <div
            className=" bg-black w-full"
            style={{
              height: 1,
            }}
          />
          <div className="text-lg py-3" data-aos="zoom-in">
            SPECIALISED IN BUSINESS LAW
          </div>
          <div
            className=" bg-black w-full"
            style={{
              height: 1,
            }}
          />
          <div className="text-lg py-3" data-aos="zoom-out">
            HIGH QUALITY LEGAL SERVICES
          </div>
        </div>
        <div align="justify" className="text-medium md:w-2/3  w-full px-4">
          Found in 2019, <b>Lexmicus Attorneys</b> is a law firm comprising of
          young but prolific and vibrant lawyers whose desire and mindset is
          versatile and dedicated towards offering quality and better legal
          services. We aim at understanding our clients’ business setups as well
          as focusing on the innovation that our client are facing and building
          in their own industries as a key factor to enables us focusing on how
          better to understand our client’s transformation in such industries.
          <br />
          <br />
          Using our wide range of resources and affiliates, our knowledge
          towards the industry as well as our business mentality and
          understanding, we aim at building trust; create value and being able
          to protect our clients’ interests. We strive to ably provide
          well-informed tailor-made, strategic and long-term solutions; in a
          timely and efficient manner.
          <br />
          <br />
          While our Team is easily accessible and attentive, it comprises of
          qualified, professional, knowledgeable and experienced personnel fully
          exposed on to day-to-day practical and business experiences and
          challenges.
        </div>
      </div>
      <div className=" text-lg  md:col-start-2 md:col-span-10 col-span-12 my-10">
        <div className="flex justify-center">
          <Link
            to="/about"
            whileHover={{ scale: 1.1 }}
            whileTap={{ scale: 0.9 }}
            className="flex   p-2 cursor-pointer transform hover:scale-2 bg-red-700 text-white "
          >
            About Us
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-6 w-6 ml-2 "
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M14 5l7 7m0 0l-7 7m7-7H3"
              />
            </svg>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default Landing;
