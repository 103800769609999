// router
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import Home from "./pages/home";
import Hoc from "./hoc";
import About from "./pages/about";
import Practice from "./pages/practice";
import MainTeam from "./pages/team";
import InsightDetail from "./pages/insight";

function App() {
  return (
    <div className="App">
      <Router>
        <Hoc>
          <Switch>
            <Route path="/" exact component={Home} />
            <Route path="/about" exact component={About} />
            <Route path="/services" exact component={Practice} />
            <Route path="/team" exact component={MainTeam} />
            <Route path="/insight/:name" exact component={InsightDetail} />
          </Switch>
        </Hoc>
      </Router>
    </div>
  );
}

export default App;
