import React, { useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/swiper.min.css";
import "swiper/components/pagination/pagination.min.css";

// import "./main.css";

// import Swiper core and required modules
import SwiperCore, { Autoplay, Pagination } from "swiper/core";
// install Swiper modules
SwiperCore.use([Autoplay, Pagination]);
function Slide() {
  const [firstAnime, setFirstAnime] = useState(false);

  const data = [
    {
      id: 1,
      image:
        "https://blogger.googleusercontent.com/img/a/AVvXsEhyEoxgS6m7OskiVD8Z0tkfRwslwSCi0PsxBLe4sNk_4_CwNcFmKhpJjDvEOh0SNXSBKCr02XRAcB-jUIzU2VdMjw8eKn23rvHpmNRdluEqMycE40Hu5xuuQb1gJ_Dx3AdeqXeLnrQrcoldYDTl-qB2ZBtlBXtZk4o7TQpbC6SnA1aNE5Ejrh3ZiNaf=s16000",
      title: "We build products which work perfectly",
      content: "DEDICATED LAWS",
    },
    {
      id: 2,
      image:
        "https://blogger.googleusercontent.com/img/a/AVvXsEi2RniszBAWvxo8IbJ8u3YBeqerTNmrI11-DKHHn9NHHwj78sE7V9g0S2kDb--GSckVjzjc0385MjYWqufP2vSXenxR8RC2VVEb9uOnRcPPOXlGDnpupm6Rjan_Htix0v6DurnDji18J7gXmh6JMng2FMU-vZND_V19mgyCfRjZQlkvQalKdQHJXlk5=s16000",
      title: "SPECIALIZED IN BUSINESS",
    },
    {
      id: 3,
      image:
        "https://1.bp.blogspot.com/-31rRofTF9ps/YP7xTHtTZ7I/AAAAAAAABVw/IFJ_kDcmgG4Aa6qzyEw3NZp5HNX22Ep8wCLcBGAsYHQ/s16000/landing.jpg",
      title: "HIGH QUALITY LEGAL SERVICE",
    },
    {
      id: 4,
      image:
        "https://images.pexels.com/photos/6056776/pexels-photo-6056776.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260",
      title: "SPECIALIZED IN BUSINESS",
    },
  ];
  return (
    <div className="w-full">
      <Swiper
        pagination={true}
        className="mySwiper w-full"
        autoplay={{
          delay: 5000,
          disableOnInteraction: false,
        }}
        onSlideChange={() => {
          setTimeout(() => {
            setFirstAnime(true);
          }, 300);
          setTimeout(() => {
            setFirstAnime(false);
          }, 5000);
        }}
        loop={true}
        loopFillGroupWithBlank={true}
      >
        {data.map((item) => (
          <SwiperSlide className="relative w-full">
            <img
              src={item.image}
              alt="thumbnail"
              className="h-screen w-full object-cover"
            />
            <div className="w-screen flex flex-col items-center   justify-center h-screen absolute top-0">
              <div className="content-slide  mx-4 md:mx-0 flex flex-col items-center   justify-center">
                <div className="h-35 overflow-hidden">
                  <div
                    className=" text-5xl text-white "
                    style={{
                      transition: ".5s",
                      transform: firstAnime
                        ? "translateY(0px)"
                        : "translateY(100px)",
                    }}
                  >
                    {item.title}
                  </div>
                </div>
                <div className="h-[10px] w-10 bg-red-700 my-6" />
              </div>
            </div>
          </SwiperSlide>
        ))}
      </Swiper>
    </div>
  );
}

export default Slide;
