import React from "react";
import AOS from "aos";
import "./../../node_modules/aos/dist/aos.css";

import { Link } from "react-router-dom";

const Team = () => {
  AOS.init({
    duration: 1000,
  });

  return (
    <div
      style={{ background: "#d2cbc2" }}
      className="grid grid-cols-12  pt-20 overflow-hidden"
    >
      <div
        data-aos="fade-left"
        className="uppercase text-lg hidden md:block underline md:col-start-2 md:col-span-10 col-span-12  pl-4 md:pl-0"
      >
        OUR TEAM
      </div>
      <div
        data-aos="zoom-out"
        className="uppercase text-lg md:hidden underline md:col-start-2 md:col-span-10 col-span-12  pl-4 md:pl-0"
      >
        OUR TEAM
      </div>

      <div
        data-aos="fade-down"
        className="  text-lg py-5  md:col-start-2 md:col-span-6 col-span-8 pl-4 md:pl-0"
      >
        We are a team of dedicated and hard-working professionals assembled to
        resolve our clients’ most complex legal challenges and exceed their
        expectations.
        <b>
          Innovation and honesty are hallmarks of Lexmicus attoney's approach.
        </b>
      </div>
      <div
        data-aos="zoom-out"
        className="uppercase text-lg flex flex-wrap items-end  md:col-start-2 md:col-span-10 col-span-12"
      >
        <img
          src="https://blogger.googleusercontent.com/img/a/AVvXsEhEuUB_sjTJ1kvP_-d5Jo3tbAEid5I6u-DopLa2yshBtVT6CDe0C-SZK3hYr1FxeDpsrNOsTrTrDNNR70C__x2V-M0kJ_jMVwVFfPdBhRmtFq3GOg3W0aV_LfGTt8sBSpDE0lr9j2DXMfii9QmaKu4ZK890BbEBW8wjrJZ3u9k-bhq-U9b1mFLc2eRY=s16000"
          alt=""
          style={{ height: "70vh", objectFit: "cover", width: "100vw" }}
        />
      </div>
      <div className=" text-lg  md:col-start-2 md:col-span-10 col-span-12 my-10">
        <div className="flex justify-center">
          <Link
            to="/team"
            className="flex   p-2 cursor-pointer transform hover:scale-2 bg-red-700 text-white "
          >
            Meet our team
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-6 w-6 ml-2 "
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M14 5l7 7m0 0l-7 7m7-7H3"
              />
            </svg>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default Team;
