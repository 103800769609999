import React from "react";
import Insight from "../components/insight";
import Landing from "../components/landing";
import Practice from "../components/practice";
import Team from "../components/team";

const Home = () => {
  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div className="w-full">
      <Landing />
      <Insight />
      <Practice />
      <Team />
    </div>
  );
};

export default Home;
