import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/swiper.min.css";
import "swiper/components/effect-coverflow/effect-coverflow.min.css";
import "swiper/components/pagination/pagination.min.css";
// import Swiper core and required modules
import SwiperCore, {
  EffectCoverflow,
  Pagination,
  Navigation,
} from "swiper/core";
import { Link } from "react-router-dom";

// install Swiper modules
SwiperCore.use([Navigation]);

// install Swiper modules
SwiperCore.use([EffectCoverflow, Pagination]);

function Insight() {
  const data = [
    {
      id: 1,
      image:
        "https://cdn.pixabay.com/photo/2017/09/07/08/54/money-2724241__480.jpg",
      title: "INVESTMENT",
    },
    {
      id: 2,
      image:
        "https://static.ohga.it/wp-content/uploads/sites/24/2020/09/carne-sudafrica.jpg",
      title: "TOURISM",
    },
    {
      id: 3,
      image:
        "https://www.semana.com/resizer/kXMoFnWHHJXqigrdYuRLfEc52gw=/1200x675/filters:format(jpg):quality(50)//cloudfront-us-east-1.images.arcpublishing.com/semana/MWP5JO3CMBBN3P2YH6LGDS7OIA.jpg",

      title: "BANKING",
    },
    {
      id: 4,
      image:
        "https://images.unsplash.com/photo-1604005250101-907e96cc2a5b?q=80&w=2572&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
      title: "TECHNOLOGY",
    },
  ];
  return (
    <div className="bg-white py-20  md:px-28">
      <div className="col-span-12 text-2xl font-black mb-4 mx-2 ">INSIGHTS</div>

      <Swiper
        loop={true}
        autoplay={{
          delay: 2000,
          disableOnInteraction: false,
        }}
        navigation={true}
        grabCursor={true}
        spaceBetween={10}
        slidesPerView={1}
        breakpoints={{
          "@0.00": {
            slidesPerView: 1,
          },
          "@0.75": {
            slidesPerView: 2,
          },
          "@1.00": {
            slidesPerView: 3,
          },
          "@1.50": {
            slidesPerView: 4,
          },
        }}
        className="mySwiper mx-2 lg:m-0"
      >
        {data.map((item) => (
          <SwiperSlide key={item.id} className="overflow-hidden rounded-xl ">
            {/* to={`/insight/${item.title.toLowerCase()}`} */}
            <div>
              <div className="relative">
                <img
                  src={item.image}
                  alt="thumbnail"
                  style={{ height: 400 }}
                  className=" w-full object-cover"
                />
                <div
                  className=" w-full absolute top-0 flex items-end justify-center"
                  style={{
                    height: 400,
                    background:
                      "linear-gradient(to bottom, rgba(0,0,0,0), black)",
                  }}
                >
                  <div className="text-2xl  text-white my-10 ">
                    {item.title}
                  </div>
                </div>
              </div>
            </div>
          </SwiperSlide>
        ))}
      </Swiper>
    </div>
  );
}

export default Insight;
