import React from "react";

import { FaFacebook } from "react-icons/fa";
import { AiFillInstagram } from "react-icons/ai";
import { ImTwitter } from "react-icons/im";
import { IoLogoLinkedin } from "react-icons/io5";
const Footer = () => {
  return (
    <div
      className="grid grid-col-12 py-10"
      style={{
        background: "#a8a8a8",
        color: "#5d554b",
      }}
    >
      <div className="w-full flex justify-center">
        <img src="logo2.png" alt="" className=" h-20 -mt-2" />
      </div>
      <div className="justify-center grid grid-cols-1 md:grid-cols-2 px-2 md:px-10 ">
        <div className=" ">
          <div className="text-lg text-gray-800 uppercase">Address</div>
          <div className="text-lg text-gray-800">
            Vikawe St, Plot No.257, House No.157, Regent Estate
          </div>
          <div className="text-lg text-gray-800">
            P.O.BOX 7063, Mikocheni, Dar Es salaam
          </div>
        </div>

        <div className="w-full flex lg:justify-end mt-4 ">
          <div>
            <div className="text-lg  text-gray-800">
              <div className="text-lg text-gray-800  uppercase">Contact</div>
              <a
                href="tel:0657572175"
                style={{
                  textDecoration: "none",
                }}
                className="text-lg text-gray-700 hover:text-red-700"
              >
                T. +255 657 572 175
              </a>
            </div>
            <div className="text-lg text-gray-800">
              <a
                href="mailto:info@lexmicus.co.tz"
                style={{
                  textDecoration: "none",
                }}
                className="text-lg text-gray-700 hover:text-red-700"
              >
                info@lexmicus.co.tz
              </a>
            </div>
          </div>
        </div>

        <div className="w-screen flex justify-center mt-10">
          <div>
            <div className="text-2xl text-gray-800 font-medium">
              Let's Connect
            </div>
            <div className="flex">
              <a
                href="/"
                className="text-lg text-gray-800 mx-2 my-4 hover:text-red-700"
              >
                <FaFacebook size={30} />
              </a>
              <a
                href="https://www.instagram.com/lexmicus.attorneys/"
                className="text-lg text-gray-800 mx-2 my-4 hover:text-red-700"
              >
                <AiFillInstagram size={30} />
              </a>
              <a
                href="https://twitter.com/lexmicus"
                className="text-lg text-gray-800 mx-2 my-4 hover:text-red-700"
              >
                <ImTwitter size={30} />
              </a>
              <a
                href="/"
                className="text-lg text-gray-800 mx-2 my-4 hover:text-red-700"
              >
                <IoLogoLinkedin size={30} />
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
