import React, { useEffect } from "react";
import AOS from "aos";
import "./../../node_modules/aos/dist/aos.css";

const About = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  AOS.init({
    duration: 1000,
  });

  return (
    <div
      style={{ background: "#d2cbc2" }}
      className="grid grid-cols-12  pt-20 overflow-hidden"
    >
      <div
        data-aos="fade-left"
        className="uppercase text-lg hidden md:block underline md:col-start-2 md:col-span-10 col-span-12  pl-4 md:pl-0"
      >
        WELCOME TO lexmicus
      </div>
      <div
        data-aos="zoom-out"
        className="uppercase text-lg md:hidden underline md:col-start-2 md:col-span-10 col-span-12  pl-4 md:pl-0"
      >
        WELCOME TO lexmicus
      </div>
      <div
        data-aos="fade-up"
        className="uppercase text-center text-lg lg:text-3xl py-5  pl-4 md:pl-0 md:col-start-2 md:col-span-10 col-span-12"
      >
        WE ARE COMMITTED TO CLEARLY UNDERSTANDING OUR CLIENTS’ NEEDS AND
        PROACTIVELY MANAGING THEIR MATTERS IN A WAY THAT FURTHERS THEIR
        STRATEGIC OBJECTIVES.
      </div>

      <div
        data-aos="zoom-out"
        className="uppercase text-lg flex flex-wrap items-end  md:col-start-2 md:col-span-10 col-span-12"
      >
        <img
          src="cover.jpeg"
          alt=""
          style={{ objectFit: "cover", width: "100vw" }}
        />
      </div>
      <div
        className=" text-lg md:col-start-3 md:col-span-8 col-span-12 my-10 pl-4 md:pl-0"
        align="justify"
      >
        <div className="text-red-700">FIRM OVERVIEW</div>
        Founded in 2019, Lexmicus Attorneys is a law firm comprising of young
        but prolific and vibrant lawyers whose desire and mindset is versatile
        and dedicated towards offering quality and better legal services. We aim
        at understanding our clients’ business as well as focusing on
        developments in the industries that our clients operates and possible
        solutions that they need.
        <br />
        <br />
        Our team of lawyers has been involved in a number of transactions
        including complex litigation, mergers and acquisitions, banking and debt
        recovery, capital markets transactions, corporate commercial work,
        corporate restructuring, insolvency, mining, energy, telecoms,
        infrastructure and real estate and general advisory work. Lexmicus’s
        lawyers have the requisite competency, knowledge, ability, and skills in
        handling complex legal matters.
        <br />
        <br />
        Using our wide range of resources and affiliates, our knowledge towards
        the industry as well as our business mentality and understanding, we aim
        at building trust; create value and being able to protect our clients’
        interests. We strive to ably provide well-informed tailor-made,
        strategic and longterm solutions; in a timely and efficient manner.
        <br />
        <br />
        Lexmicus regards efficiency, responsiveness and a commercial approach to
        problem solving as vital to the provision of legal advice to its
        sophisticated client base. We aim to have a specialist focus and ability
        to deliver timely and efficient legal services. We value the development
        and cultivation of longterm client relationships and focus on
        understanding our clients’ business needs and requirements so that we
        can augment and partner in their growth and development.
        <br />
        <br />
        We have the right people and the relevant expertise and experience to
        support you and we are confident the team can deliver the highest
        quality legal advice in a cost-effective and efficient manner.
        <div className="text-red-700 mt-10">REPRESENTATIVE MATTERS</div>
        <i>
          Lexmicus’s local expertise across a broad range of practice groups and
          sub-sectors, combined with its business sense, has positioned it as
          the firm of choice for a number of complex cross-border deals and
          transactions which include:
        </i>
        <ul className="list-disc">
          <li>
            Acting for and advising Solvochem Tanzania, one of leading dealer of
            petrochemicals, with respect to its disputes with the Government of
            Tanzania in relation to a land and petrochemicals infrastructure in
            Mtwara.
          </li>
          <li>
            Acting for Solvochem Tanzania in two debt recovery claims for breach
            of Supply petrochemicals Contracts.
          </li>
          <li>
            Acting Dough Works Limited on the investment and acquisition of
            shares into Dough Works (Pizza Hut) by Kibo Foods (KFC), Lexmicus
            role included undertaking necessary corporate and tax fillings for
            completion of the transaction.
          </li>
          <li>
            Advising Blue Coast Investment Limited in getting a permit and local
            content authority as subcontractors in mining operations.
          </li>
          <li>
            Undertaking detailed corporate due diligence on various companies
            incorporated in Tanzania and necessary remedial actions to rectify
            the anomalies identified and updating company records with the
            Registrar of Companies.
          </li>
          <li>
            Acting as Legal Adviser of the Reinsurance Company, the office has
            been in charge of all compliances at TIRA, BRELA, TRA, EWURA and
            other regulatory bodies.
          </li>
          <li>
            Undertaking a due diligence on Bougainvillea Group of Lodges and
            advice on the group restructuring and good governance.
          </li>
          <li>
            Advising Rafiki Coffee Limited on the acquisition of the entire
            issued share capital of Rafiki by ED&F Commodities and its nominee.
            Lexmicus role includes the drafting of the transaction documents,
            advising on and undertake the conditions precedent and undertaking
            necessary corporate and tax fillings for completion of the
            transaction.
          </li>
          <li>
            Acting for Oxley Limited in a debt recovery claim for breach of
            Joint Venture Agreement in relating the mining drilling operations
            and related services.
          </li>
          <li>
            Advising Nyanza Salt Mines (T) Limited in relation to the process of
            dealing with shares of untraceable shareholder. Leximcus role
            included obtaining a landmark decision from the High Court of
            Tanzania on the treatment of untraceable shareholder, drafting of
            the transaction documents, advising on and undertake the conditions
            precedent (including steps advised by the Court) and undertaking
            necessary corporate and tax fillings for completion of the
            transaction.
          </li>
          <li>
            Advising MAC Group Limited, in connection with the acquisition of
            Nyanza Salt Mines (T) Limited, involved in the business of
            production and sale of salt. Lexmicus role includes the drafting of
            the transaction documents, advising on and undertake the conditions
            precedent including applying for competition approval from the Fair
            Competition Commission and approval form the mining regulator, the
            Mining Commission and undertaking necessary corporate and tax
            fillings for completion of the transaction
          </li>
          <li>
            Acting for Taylor Winch (Tanzania) Limited with respect to corporate
            and regulatory compliance and undertaking a due diligence on the
            company and rectification of the anomalies.
          </li>
          <li>
            Advising suppliers to the mining and oil and gas industries in
            connection with local content rules for supply chain to these
            industries, including liaising with the regulators and other
            stakeholders, reviewing clients’ documents in line with the with the
            mining local content requirements as suppliers to holders of mineral
            rights licences in Tanzania, advising on permissible structures,
            drafting local content plans and pre-qualification submissions to
            the regulators and drafting correspondence with suppliers and
            customers for compliance with the new laws.
          </li>
          <li>
            Advising various investors on mining joint ventures including
            prospecting licences and primary mining licences and on purchases of
            shares in mining companies.
          </li>
          <li>
            Acting for Consolidated Tourist & Hotel Investment Limited (Sopa
            Lodges), one of the leading operators in tourist lodges in the
            region, with respect to renewal of its leases with Tanzania National
            Park Authority (TANAPA) and Ngorongoro Conservation Area Authority
            (NCAA)
          </li>
          <li>
            Acting for Upper Zonal Trading Limited, a specialist distributor of
            wine and other liquor products, with respect to the investment in
            the company by Vinimark International Proprietary Limited, the
            largest independent wine specialist company in South Africa.
            Lexmicus role includes reviewing, drafting and negotiate the
            transaction documents and undertake the conditions precedent
            including applying for competition approval from the Fair
            Competition Commission and undertaking necessary corporate fillings
            for completion of the transaction
          </li>
          <li>
            Acting as professional company secretaries and assisting various
            companies with setting up and obtaining necessary licences, permits,
            registration and ongoing compliance to authorize them to operate in
            Tanzanian.
          </li>
        </ul>
      </div>
    </div>
  );
};

export default About;
