import React from "react";

import AOS from "aos";
import "./../../node_modules/aos/dist/aos.css";

const MainTeam = () => {
  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  AOS.init({
    duration: 1000,
  });
  return (
    <div className="grid grid-col-12 mt-20">
      <div
        data-aos="fade-up"
        className="uppercase lg:text-5xl md:text-3xl xl:text-6xl text-2xl py-5  pl-4 md:pl-0 md:col-start-2 md:col-span-10 col-span-12"
      >
        KNOWLEDGEABLE & COMMITED PEOPLE.
      </div>
      <div className=" text-lg   col-span-12 ">
        {/* -----------------denice------------------- */}
        <div className="p-10 ">
          <div
            style={{
              color: "#8C191B",
            }}
            className="my-4"
            data-aos="zoom-out"
          >
            <b>DENICE TUMAINI || Managing Partner</b>
          </div>
          <div className="flex flex-wrap md:flex-nowrap">
            <div
              className="md:w-2/3 w-full md:pr-4"
              align="justify"
              data-aos="fade-right"
            >
              Denice Tumaini practices in Commercial and Civil Litigation,
              Judicial Review, Tax Disputes, Arbitration, Receivership,
              Intellectual Property, Sports and Entertainment, Insurance,
              banking and Mortgage Financing, corporate aspects as well as Real
              estate and Conveyance. He is a duly admitted and enrolled Advocate
              of the High Court of Tanzania and Court’s subordinate thereto
              serve for the Primary Courts. He possesses enormous field
              experience, knowledge and skills acquired under the mentorship of
              one of the litigation Moguls in Tanzania Mr. Beatus Malima;
              Recovery, merger and acquisition Expert Mr. Joseph Nuwamanya and
              Corporate aspects from the renown corporate and Tax Law expert
              Lemmy Bartholomew of Mawalla Advocates. Under the supervision and
              guidance the experts at Mawalla Advocates, he has developed to
              become an expert with multiple and exceptional solutions on the
              problems and challenges arising on his field of practice.
              <div>
                <a href="/denice.pdf" target="_blank">
                  <button className="px-2 py-1 bg-red-700 text-white flex my-4">
                    Read more
                  </button>
                </a>
                Managing Partner <br />
                Plot No. 257, Regent Estate, <br />
                Vikawe St, Mikocheni, <br />
                P.O.BOX 7063, <br />
                Dar Es salaam.
                <br />
                <br />
                T: +255 657 572 175
                <br /> E: denice.tumaini@lexmicus.co.tz
              </div>
            </div>
            <div
              className="relative md:w-1/3 w-full  px-2 md:pl-2 md:pr-0"
              data-aos="fade-left"
            >
              <img
                src="https://1.bp.blogspot.com/-83gN1D9V214/YP73YRbrK7I/AAAAAAAABWg/KnBKVdOPoiAsFZvkCsqZ686nYOQ1RJEhQCLcBGAsYHQ/s16000/DENIS.jpg"
                alt="pic"
                className=" w-full  object-cover"
              />
              <div
                className="absolute bottom-0 w-full md:pl-2 px-2"
                style={{
                  height: "40vh",
                  backgroundImage:
                    "linear-gradient(to bottom, rgba(0,0,0,0), #f5f5f5)",
                }}
              />
            </div>
          </div>
        </div>

        {/* ----------------------ANDREW SECHA-------------------- */}
        <div
          className="p-10 "
          style={{
            background: "#d1d4da",
          }}
        >
          <div
            style={{
              color: "#8C191B",
            }}
            className="my-4"
            data-aos="zoom-out"
          >
            <b>ANDREW SECHA || Partner</b>
          </div>
          <div className="flex flex-wrap md:flex-nowrap">
            <div
              className="relative md:w-1/3 w-full  px-2 md:pl-2 md:pr-0"
              data-aos="fade-right"
            >
              <img
                src="https://1.bp.blogspot.com/-_orVDetxnwg/YP73ouv3bZI/AAAAAAAABWo/WO3-QwRpKIs-thm68ptK27OAEM082mb4wCLcBGAsYHQ/s16000/ANDREW.jpg"
                alt="pic"
                className=" object-cover"
              />
              <div
                className="absolute bottom-0 w-full md:pl-2 px-2"
                style={{
                  height: "40vh",
                  backgroundImage:
                    "linear-gradient(to bottom, rgba(0,0,0,0), #d1d4da)",
                }}
              />
            </div>
            <div
              className="md:w-2/3 w-full md:pl-4"
              align="justify"
              data-aos="fade-left"
            >
              Andrew Secha is an Advocate of the High Court of Tanzania and
              Courts Subordinate thereto save for the Primary Courts. He works
              as the head of the Corporate and recovery department at Lexmicus
              Attorneys and also act as Administrative Partner. Andrew Secha
              worked at Apex Attorneys (Advocates) and GEKC Attorneys, he has
              built a carrier and gained experience around Corporate at all its
              levels. His other specializations include banking law, oil & gas,
              mining sector taxation, company law, corporate finance and
              corporate taxation. He has expertise in arbitration and other
              means to the Alternative Dispute Resolution. He brings to the team
              not only corporate skills but also negotiation and arbitration
              skills which he has acquired over a time.
              <div>
                <a href="/andrew.pdf" target="_blank">
                  <button className="px-2 py-1 bg-red-700 text-white flex my-4">
                    Read more
                  </button>
                </a>
                Partner <br />
                Plot No. 257, Regent Estate, <br />
                Vikawe St, Mikocheni, <br />
                P.O.BOX 7063, <br />
                Dar Es salaam.
                <br />
                <br />
                T: +255 715 850 450 <br />
                E: andrew.secha@lexmicus.co.tz
              </div>
            </div>
          </div>
        </div>

        {/* ----------------------------MCIHAEL BEZEGE------------------------ */}
        <div className="p-10 ">
          <div
            style={{
              color: "#8C191B",
            }}
            className="my-4"
            data-aos="zoom-out"
          >
            <b>MICHAEL BENZEGE || Associate</b>
          </div>
          <div className="flex flex-wrap md:flex-nowrap">
            <div
              className="md:w-2/3 w-full md:pr-4"
              align="justify"
              data-aos="fade-right"
            >
              Michael Benzege is an Advocate of the High Court of Tanzania and
              Courts Subordinate thereto save for the Primary Courts. He
              specializes in corporate, banking and financing, Human rights,
              insurance, social security, securities and trademark portfolio
              management, including prosecuting trademark applications and
              handling oppositions. He advises clients of tax planning, estate
              planning, probate and administration of estate matters. He handles
              our corporate clients providing legal services ranging from
              compliance, management and security and audit perfection. He
              manages trademark portfolios for clients in Tanzania.
              <div>
                <a href="/michael.pdf" target="_blank">
                  <button className="px-2 py-1 bg-red-700 text-white flex my-4">
                    Read more
                  </button>
                </a>
                Associate <br />
                Plot No. 257, Regent Estate, <br />
                Vikawe St, Mikocheni, <br />
                P.O.BOX 7063, <br />
                Dar Es salaam.
                <br />
                <br />
                T: +255 762 093 201
                <br /> E: michael.benzege@lexmicus.co.tz
              </div>
            </div>
            <div
              className="relative md:w-1/3 w-full  px-2 md:pl-2 md:pr-0"
              data-aos="fade-left"
            >
              <img
                src="https://1.bp.blogspot.com/-jDJjfIQWJIo/YP73yFJuGpI/AAAAAAAABWs/mllcMejr4_M0OHx0Ck3Gg25gvKFBXBxgACLcBGAsYHQ/s16000/MIKE.jpg"
                alt="pic"
                className=" w-full  object-cover"
              />
              <div
                className="absolute bottom-0 w-full md:pl-2 px-2"
                style={{
                  height: "40vh",
                  backgroundImage:
                    "linear-gradient(to bottom, rgba(0,0,0,0), #f5f5f5)",
                }}
              />
            </div>
          </div>
        </div>

        {/* -------------------------GERADINA PAUL ------------------------------ */}
        <div
          className="p-10   "
          style={{
            background: "#d1d4da",
          }}
        >
          <div
            style={{
              color: "#8C191B",
            }}
            className="my-4"
            data-aos="zoom-out"
          >
            <b>GERADINA PAUL || Associate</b>
          </div>
          <div className="flex flex-wrap md:flex-nowrap">
            <div
              className="relative md:w-1/3 w-full  px-2 md:pl-2 md:pr-0"
              data-aos="fade-right"
            >
              <img
                src="https://1.bp.blogspot.com/-BpD4pY7ncJo/YP73-ktGxtI/AAAAAAAABW0/kJIUhumFHZYi98r2nrS3d7DhaWfUq8XLACLcBGAsYHQ/s16000/DINA.jpg"
                alt="pic"
                className=" object-cover"
              />
              <div
                className="absolute bottom-0 w-full md:pl-2 px-2"
                style={{
                  height: "40vh",
                  backgroundImage:
                    "linear-gradient(to bottom, rgba(0,0,0,0), #d1d4da)",
                }}
              />
            </div>
            <div
              className="md:w-2/3 w-full md:pl-4"
              align="justify"
              data-aos="fade-left"
            >
              Geraldina Paul is the graduate of Mzumbe University, specialized
              in corporate, taxation, insurance, immigration banking and finance
              matters. She has experienced in drafting and reviewing of various
              corporate and financial documents. She advises clients on setting
              up business in Tanzania including formation of a company,
              corporate reorganization, registration of companies, trusts,
              NGO's, societies, trade and service marks and patents as well as
              their post registration/compliance activities, due diligence
              investigation and also on general corporate litigation and civil
              litigation.
              <div>
                <a href="/dina.pdf" target="_blank">
                  <button className="px-2 py-1 bg-red-700 text-white flex my-4">
                    Read more
                  </button>
                </a>
                Associate <br />
                Plot No. 257, Regent Estate, <br />
                Vikawe St, Mikocheni, <br />
                P.O.BOX 7063, <br />
                Dar Es salaam.
                <br />
                <br />
                T: +255 719 210 069
                <br /> E: geraldina.paul@lexmicus.co.tz
              </div>
            </div>
          </div>
        </div>

        {/* ---------------------------FLORA---------------------------------- */}
        <div className="p-10 ">
          <div
            style={{
              color: "#8C191B",
            }}
            className="my-4"
            data-aos="zoom-out"
          >
            <b>FLORA SHAANAELI MURO || Administrative Assistant</b>
          </div>
          <div className="flex flex-wrap md:flex-nowrap">
            <div
              className="md:w-2/3 w-full md:pr-4"
              align="justify"
              data-aos="fade-right"
            >
              Flora is a vibrant individual passionate about creating
              administrative efficiencies. She is very inquisitive with a joy in
              exploring new ways in doing things to meet business objectives. A
              compassionate and open-minded professional with the ability to
              adapt to different environments and contribute overall value. She
              is an excellent communicator in written and spoken language and a
              team player.
              <div>
                <a href="/flora.pdf" target="_blank">
                  <button className="px-2 py-1 bg-red-700 text-white flex my-4">
                    Read more
                  </button>
                </a>
                Administrative Assistant <br />
                Plot No. 257, Regent Estate, <br />
                Vikawe St, Mikocheni, <br />
                P.O.BOX 7063, <br />
                Dar Es salaam.
                <br />
                <br />
                T: +255 673 777 647
                <br /> E: info@lexmicus.co.tz
              </div>
            </div>
            <div
              className="relative md:w-1/3 w-full  px-2 md:pl-2 md:pr-0"
              data-aos="fade-left"
            >
              <img
                src="flora.jpeg"
                alt="pic"
                className=" w-full  object-cover"
              />
              <div
                className="absolute bottom-0 w-full md:pl-2 px-2"
                style={{
                  height: "40vh",
                  backgroundImage:
                    "linear-gradient(to bottom, rgba(0,0,0,0), #f5f5f5)",
                }}
              />
            </div>
          </div>
        </div>
        {/* -------------------------NIXON YONA --------------------------------- */}
        <div
          className="p-10   "
          style={{
            background: "#d1d4da",
          }}
        >
          <div
            style={{
              color: "#8C191B",
            }}
            className="my-4"
            data-aos="zoom-out"
          >
            <b>NIXON YOHANA || Legal Officer</b>
          </div>
          <div className="flex flex-wrap md:flex-nowrap">
            <div
              className="relative md:w-1/3 w-full  px-2 md:pl-2 md:pr-0"
              data-aos="fade-right"
            >
              <img src="nixon.jpeg" alt="pic" className=" object-cover" />
              <div
                className="absolute bottom-0 w-full md:pl-2 px-2"
                style={{
                  height: "40vh",
                  backgroundImage:
                    "linear-gradient(to bottom, rgba(0,0,0,0), #d1d4da)",
                }}
              />
            </div>
            <div
              className="md:w-2/3 w-full md:pl-4"
              align="justify"
              data-aos="fade-left"
            >
              Nixon is an energetic and dynamic graduate legal officer and
              aspiring advocate of the high court of Tanzania. He is an
              effective communicator and has an incredible ability for attention
              to detail whilst executing legal research for building cases and
              gathering necessary evidence. A great communicator capable of
              presenting large amounts of facts effectively.
              <div>
                <a href="/nixon.pdf" target="_blank">
                  <button className="px-2 py-1 bg-red-700 text-white flex my-4">
                    Read more
                  </button>
                </a>
                Legal Officer <br />
                Plot No. 257, Regent Estate, <br />
                Vikawe St, Mikocheni, <br />
                P.O.BOX 7063, <br />
                Dar Es salaam.
                <br />
                <br />
                T: +255 785 999 575
                <br /> E: intern@lexmicus.co.tz
              </div>
            </div>
          </div>
        </div>
        {/* -----------------------------SECHA ----------------------------------- */}
        <div className="p-10  ">
          <div
            style={{
              color: "#8C191B",
            }}
            className="my-4"
            data-aos="zoom-out"
          >
            <b>ALBERT CHARLES || IT Specialist</b>
          </div>
          <div className="flex flex-wrap md:flex-nowrap">
            <div
              className="md:w-2/3 w-full md:pl-4"
              align="justify"
              data-aos="fade-left"
            >
              <div>
                Albert Secha is a Technology and Innovation enthusiast from
                Tanzania. He received his Bachelor of Science in
                Telecommunications Engineering from University of Dar Es Salaam.
                He has also attaended a special course on IT Security from both
                Polytechnic University of Hong Kong and CyberSmart Ltd in
                London,UK. He has been involved in many different Tech and
                Innovation related initiatives in country and abroad, such as
                Silicon Dar and World Bank Group Youth Summit with the theme of
                “Technology and Innovation for Impact” at World Bank Group Head
                Quarters, Washington DC, 2017. At the moment other than being IT
                Specialist at Lexmicus, he is the Head of Operations at Worknasi
                Com LTD and consulting a number entities such as Wasafi Media
                Group, CV People Tanzania, WIMA and Rank Attorneys. In his spare
                time he enjoys traveling and music of all kind from Gospel to
                Bongo Fleva.
              </div>
              <div>
                <a href="/albert.pdf">
                  <button className="px-2 py-1 bg-red-700 text-white flex my-4">
                    Read more
                  </button>
                </a>
                It Specialist <br />
                Plot No. 257, Regent Estate, <br />
                Vikawe St, Mikocheni, <br />
                P.O.BOX 7063, <br />
                Dar Es salaam.
                <br />
                <br />
                T: +255 717 618 218 <br />
                E: sechaalbert@lexmicus.co.tz
              </div>
            </div>
            <div
              className="relative md:w-1/3 w-full  px-2 md:pl-2 md:pr-0"
              data-aos="fade-right"
            >
              <img
                src="https://1.bp.blogspot.com/-h3liwqwHhOE/YP_RImX1XtI/AAAAAAAABXU/-PcgGv89CEwNtexoOFv7HUCZLw_440o_ACLcBGAsYHQ/s16000/ALBERT.jpg"
                alt="pic"
                className=" object-cover"
              />
              <div
                className="absolute bottom-0 w-full md:pl-2 px-2"
                style={{
                  height: "40vh",
                  backgroundImage:
                    "linear-gradient(to bottom, rgba(0,0,0,0), #dbeafe)",
                }}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MainTeam;
