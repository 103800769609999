import React, { useEffect, useState } from "react";
import NavBar from "./components/navbar";
import Welcome from "./components/welcome";
import Footer from "./components/footer";

const Hoc = ({ children }) => {
  const [loading, setloading] = useState(true);

  useEffect(() => {
    setTimeout(() => {
      setloading(false);
    }, 2000);
  }, [setloading]);

  return (
    <div>
      {loading ? (
        <div className="bg-black flex items-center justify-center h-screen w-screen">
          <img src="logo2.png" alt="" className=" h-20 -mt-2 animate-pulse" />
        </div>
      ) : (
        <div className="overflow-hidden w-full">
          <NavBar />

          {children}
          <Footer />
        </div>
      )}
    </div>
  );
};

export default Hoc;
