import React, { useEffect, useState } from "react";
import { CgMenuRightAlt } from "react-icons/cg";

import { NavLink } from "react-router-dom";

const NavBar = () => {
  const [navScroll, setNavScroll] = useState(false);
  const [expand, setExpand] = useState(false);

  useEffect(() => {
    window.onscroll = () => {
      if (window.scrollY <= 100) {
        setNavScroll(false);
      } else {
        setNavScroll(true);
      }
    };
  }, []);
  return (
    <div
      className="flex justify-between px-2 w-screen items-center pb-2 uppercase text-base"
      style={{
        background: "#8C191B",
        transition: ".5s",
        position: "fixed",
        zIndex: 100,
      }}
    >
      <NavLink
        to="/"
        className="h-1/4 pt-4  mx-2"
        style={{
          textDecoration: "none",
        }}
      >
        <img src="logored.jpg" alt="" className=" h-10 -mt-2" />
      </NavLink>

      {!expand && (
        <div
          className="sm:hidden cursor-pointer  absolute  right-0 text-white mx-4"
          onClick={() => {
            setExpand(true);
          }}
        >
          <CgMenuRightAlt size={27} />
        </div>
      )}

      <div
        className={
          expand
            ? "flex flex-col transition duration-1000 sm:hidden w-screen items-end absolute -ml-2 mt-20 p-10 bg-red-700  text-white"
            : "h-0 overflow-hidden w-0"
        }
      >
        <div
          className="py-2 cursor-pointer mt-10 "
          onClick={() => {
            setExpand(false);
          }}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="h-6 w-6"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={2}
              d="M6 18L18 6M6 6l12 12"
            />
          </svg>
        </div>
        <NavLink
          to="/"
          exact
          style={{ textDecoration: "none" }}
          activeStyle={{
            borderBottom: "2px solid white",
          }}
          className="mx-2 hover:text-gray-400"
        >
          Home
        </NavLink>
        <NavLink
          to="/about"
          exact
          style={{ textDecoration: "none" }}
          activeStyle={{
            borderBottom: "2px solid white",
          }}
          onClick={() => {
            setExpand(false);
          }}
          className="mx-2 hover:text-gray-400"
        >
          About Us
        </NavLink>
        <NavLink
          to="/services"
          exact
          style={{ textDecoration: "none" }}
          activeStyle={{
            borderBottom: "2px solid white",
          }}
          onClick={() => {
            setExpand(false);
          }}
          className="mx-2 hover:text-gray-400"
        >
          Practice Areas
        </NavLink>
        <NavLink
          to="/team"
          exact
          onClick={() => {
            setExpand(false);
          }}
          style={{ textDecoration: "none" }}
          activeStyle={{
            borderBottom: "2px solid white",
          }}
          className="mx-2 hover:text-gray-400"
        >
          Team
        </NavLink>
        <a
          href="tel:+255657572175"
          exact
          style={{
            color: "#8C191B",
            textDecoration: "none",
          }}
          onClick={() => {
            setExpand(false);
          }}
          className="mx-2 hover:text-gray-400 p-2 rounded-lg bg-white "
        >
          Contact
        </a>
      </div>

      <div className=" hidden sm:block text-white px-2">
        <div className="flex items-center">
          <div className="flex">
            <NavLink
              to="/"
              exact
              style={{ textDecoration: "none" }}
              activeStyle={{
                borderBottom: "2px solid white",
              }}
              className="mx-10 hover:text-gray-400 relative"
              onClick={() => {
                setExpand(false);
              }}
            >
              Home
            </NavLink>
            <div>|</div>
          </div>

          <div className="flex">
            <NavLink
              to="/about"
              exact
              style={{ textDecoration: "none" }}
              activeStyle={{
                borderBottom: "2px solid white",
              }}
              onClick={() => {
                setExpand(false);
              }}
              className="mx-10 hover:text-gray-400"
            >
              About Us
            </NavLink>

            <div>|</div>
          </div>

          <div className="flex">
            <NavLink
              to="/services"
              exact
              style={{ textDecoration: "none" }}
              activeStyle={{
                borderBottom: "2px solid white",
              }}
              onClick={() => {
                setExpand(false);
              }}
              className="group mx-10 relative hover:text-gray-400"
            >
              Practice Areas
              <div className="absolute hidden group-hover:flex top-4 left-0  py-6  flex-col items-start text-black  ">
                <div className="flex flex-col bg-gray-400 p-4 rounded-2xl flex-start">
                  <NavLink
                    to="/services"
                    exact
                    style={{ textDecoration: "none" }}
                    onClick={() => {
                      setExpand(false);
                    }}
                    className="mx-2 relative  "
                  >
                    Corporate
                  </NavLink>
                  <NavLink
                    to="/services"
                    exact
                    style={{ textDecoration: "none" }}
                    onClick={() => {
                      setExpand(false);
                    }}
                    className="mx-2 relative "
                  >
                    Banking
                  </NavLink>
                  <NavLink
                    to="/services"
                    exact
                    style={{ textDecoration: "none" }}
                    onClick={() => {
                      setExpand(false);
                    }}
                    className="mx-2 relative "
                  >
                    Litigation
                  </NavLink>
                  <NavLink
                    to="/services"
                    exact
                    style={{ textDecoration: "none" }}
                    onClick={() => {
                      setExpand(false);
                    }}
                    className="mx-2 relative "
                  >
                    Labour
                  </NavLink>
                  <NavLink
                    to="/services"
                    exact
                    style={{ textDecoration: "none" }}
                    onClick={() => {
                      setExpand(false);
                    }}
                    className="mx-2 relative "
                  >
                    Land Law
                  </NavLink>
                  <NavLink
                    to="/services"
                    exact
                    style={{ textDecoration: "none" }}
                    onClick={() => {
                      setExpand(false);
                    }}
                    className="mx-2 relative "
                  >
                    Mining
                  </NavLink>
                  <NavLink
                    to="/services"
                    exact
                    style={{ textDecoration: "none" }}
                    onClick={() => {
                      setExpand(false);
                    }}
                    className="mx-2 relative "
                  >
                    Investiments
                  </NavLink>
                  <NavLink
                    to="/services"
                    exact
                    style={{ textDecoration: "none" }}
                    onClick={() => {
                      setExpand(false);
                    }}
                    className="mx-2 relative "
                  >
                    Tourism
                  </NavLink>
                  <NavLink
                    to="/services"
                    exact
                    style={{ textDecoration: "none" }}
                    onClick={() => {
                      setExpand(false);
                    }}
                    className="mx-2 relative "
                  >
                    Technology
                  </NavLink>
                </div>
              </div>
            </NavLink>

            <div>|</div>
          </div>

          <div className="flex">
            <NavLink
              to="/team"
              exact
              style={{ textDecoration: "none" }}
              activeStyle={{
                borderBottom: "2px solid white",
              }}
              onClick={() => {
                setExpand(false);
              }}
              className="mx-10 hover:text-gray-400"
            >
              Team
            </NavLink>
          </div>

          <a
            href="tel:+255657572175"
            exact
            style={{
              color: "#8C191B",
              textDecoration: "none",
            }}
            className="mx-10 hover:text-gray-400 p-2 rounded-lg bg-white "
          >
            Contact
          </a>
        </div>
      </div>
    </div>
  );
};

export default NavBar;
