import React from "react";
import { NavLink } from "react-router-dom";

function Practice() {
  return (
    <div className="w-full px-2 ">
      <div className="    text-2xl   mt-28">PRACTICE AREA</div>
      <div className="grid w-full   grid-cols-1 lg:grid-cols-2 gap-10">
        <div
          className="shadow-xl bg-white  px-2 flex flex-col items-start my-4"
          style={{
            boxShadow: "0 0 20px rgba(0,0,0,.2)",
          }}
        >
          <div className="text-3xl  text-left my-4">
            CORPORATE GOVERNANCE
            <b className="text-gray-500">& COMMERCIAL DRAFTING</b>
          </div>
          <div align="justify">
            We advise on all matters of Corporate Law in Tanzania and commercial
            drafting to public and private companies, start-ups, emerging
            businesses and Non-Governmental Organizations. We cater for all
            corporate and secretarial services from incorporation of a corporate
            entity, compliance, restructuring to the dissolution of such entity.
            We also do registration of trademarks, patents and service marks.
            <br />
            <br />
            Our lawyers are equipped with the necessary skills and experience to
            provide commercial advice on corporate and public entities. We
            advise our clients on the essential elements of a wide range of
            commercial contracts including distribution, supply, outsourcing,
            franchise and joint venture agreements. Our precise and well-crafted
            commercial contracts are designed to ensure that our clients’
            interests are at all times protected and reduce exposure to future
            unwarranted disputes and risks.
          </div>
        </div>
        <div
          className="shadow-xl bg-white p-6 flex flex-col items-start my-4"
          style={{
            boxShadow: "0 0 20px rgba(0,0,0,.2)",
          }}
        >
          <div className="text-3xl font-black text-left my-4">
            LITIGATION & ALTERNATIVE
            <b className="text-gray-500"> &nbsp; DISPUTE RESOLUTION</b>
          </div>
          <div align="justify">
            Unlike many others, our practice and business-oriented mentality
            makes us offer exceptional litigation services to our clients. Our
            services are designed with a view that litigation is time consuming,
            costing and stressful; thus, we focus on the measures that bring
            quick but efficient, sustainable and reliable results. We have
            seasoned litigation lawyers with requisite practical skills and
            experience in litigating. They have been involved in different
            complex and simple case in different fields such as construction,
            contracts, creditor disputes, employment issues, fraud, securities
            and shareholders disputes.
          </div>
        </div>
        <div
          className="shadow-xl bg-white p-6 flex flex-col items-start my-4"
          style={{
            boxShadow: "0 0 20px rgba(0,0,0,.2)",
          }}
        >
          <div className="text-3xl font-black text-left my-4">INVESTMENTS</div>
          <div align="justify">
            We are the Firm with a clear understanding of the investment climate
            in Tanzania as well as an extensive commercial law practice related
            to local and international business transactions. We have a vast
            knowledge on the emerging areas of investment in Tanzania and
            devised special purposes vehicles for investment purposes that take
            into account aspects of tax planning and compliance with the laws of
            the country. We advise and take lead in negotiations to acquire
            investments through the Tanzania Investment Centre.
            <br />
            <br />
            Our services cover all aspects of investment in the country ranging
            from drafting contracts and agreements to infrastructure, taxation,
            and stock exchange. We offer all services including Share/Stock
            Purchase Agreements and Shareholders Agreements; Indemnity and a
            variety of investment agreements; and Applications for registration
            of projects and businesses for investment incentives and tax
            exemptions.
          </div>
        </div>
        <div
          className="shadow-xl bg-white p-6 flex flex-col items-start my-4"
          style={{
            boxShadow: "0 0 20px rgba(0,0,0,.2)",
          }}
        >
          <div className="text-3xl font-black text-left my-4">
            TECHNOLOGY
            <b className="text-gray-500"> & TELECOMMUNICATION</b>
          </div>
          <div align="justify">
            We fulfill the changing needs of our clients in today’s rapidly
            evolving technological environment. We counsel both emerging and
            established companies engaged in technology-related businesses such
            as telecommunication and e-business. Our telecommunication and
            technology services include representation of our clients in all
            regulatory compliance matters with the regulatory authorities
            including the TCRA, and acquisition of the requisite licences and
            the safeguard of their technology through, trademark, copyright and
            trade secret protection and defense. We assist our clients in
            maximizing the value of their technology and intellectual property
            assets through management, licensing and other strategic agreements.
            We provide solutions for our clients’ trademark, trade dress, unfair
            competition, and trade secret concerns
          </div>
        </div>
      </div>
      <div className="col-span-12 text-base text-white flex justify-center  ">
        <NavLink
          to="/practice"
          className=" bg-red-600 py-2 px-4 w-30 my-4 hover:bg-black cursor-pointer"
        >
          View More
        </NavLink>
      </div>
    </div>
  );
}

export default Practice;
